export default {
  init() {
    // JavaScript to be fired on the about us page

    var firstNames = window.getUrlParameter('FIRST_NAMES');
    var lastName = window.getUrlParameter('LAST_NAME');
    var ownerId = window.getUrlParameter('ID');
    var registered = window.getUrlParameter('REGISTERED');

    if (registered == 'registered-owner') {
      $('#previouslyRegistered').prop('checked', true);
    }
    
    $('#firstNamesInput').val(firstNames);
    $('#lastNameInput').val(lastName);
    
    if ($('#shn').length > 0) {
        $('#shn').val(ownerId);
    }

    //
    // Conditional validation for contact details
    //
    
    var ownerInputs = $('#emailInput,#phoneInput,#mobileInput');
    ownerInputs.on('input', function () {
      //loop through inputs and clear custom validity message. If value not an empty string then field is considered invalid
      ownerInputs.each(function () {
        $(this)[0].setCustomValidity("");
      });
      // Set the required property of the other inputs to false if this input is not empty.
      ownerInputs.not(this).prop('required', !$(this).val().length);
      // make the focused field required 
      $(this).prop('required', true);
    });
    
    ownerInputs.on('invalid', function () {
      //Clear custom validity message. If value not an empty string then fields iare considered invalid
      this.setCustomValidity("");
      if (!this.validity.valid) {
        this.setCustomValidity("Please include either an email address, phone, or mobile phone number.");
      }
    });
    
    // add required to email if none of the ownerInput fields are required. 
    // They aren't all requred because HTML forms plugin does server side validation that cant be removed by toggling required property.
    $('.hf-form-191 button[type="submit"]').bind("focus, mouseenter", function() {
      var anyRequired = false;
      ownerInputs.each(function() {
        if ($(this)[0].hasAttribute('required')) {
          anyRequired = true;
        }
      });
      if(!anyRequired) {
        $('#emailInput').prop('required', true);
      }
    });
   


    // 
    // copy owner values across to enquirer
    // 
    var ownerFields = {
      address: $('#addressInput'),
      region: $('#regionInput'),
      city: $('#city'),
      country: $('#country'),
      postalCode: $('#postalCode'),
    };
    var enquirerFields = {
      address: $('#enquirerAddressInput'),
      region: $('#enquirerRegionInput'),
      city: $('#enquirerCity'),
      country: $('#enquirerCountry'),
      postalCode: $('#enquirerPostalCode'),
    };
    $('#copyOwnerAddress').click(function() {
      $.each(ownerFields, function (index, input) {
        var ownerValue = input.val();
        enquirerFields[index].val(ownerValue);
      });
    });

    //
    // Conditional form fields.
    //
    $('#isOwner').change(function() {
      $('#enquirerDetails').fadeToggle();
    });
    $('#enquirerRelationship').change(function () {
      if($(this).val() == 'Other') {
        $('.other-relationship').fadeIn();
      } else {
        $('.other-relationship').fadeOut();
      }
    })
    
  },
};
