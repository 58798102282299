export default {
  init() {
    // JavaScript to be fired on all pages
    const accordian = $('#ownerAccordian');
    const ownersByLastName = $('#ownersByLastName');
    const list = $('.owners-by-name__list');

    $('.owner-button').click(function() {

        var lastName = $(this).text();

        $.ajax({
            url: window.ajax_object.ajax_url,
            type : 'POST',
            data : {
                action : 'get_owners_by_last_name',
                last_name : lastName,
                security : window.ajax_object.ajax_nonce,
            },
            success : function( data ) {
                accordian.hide();
                ownersByLastName.fadeToggle();
                var owners = jQuery.parseJSON(data);
                owners.sort((a, b) => a.first_names.localeCompare(b.first_names));
                $('.owners-by-name__surname').text(owners[0].last_name);
                list.empty();

                //splitting list of names into arrays of equal length. If not equal then last column gets the rest.
                var chunk_size = Math.floor(owners.length / 4);
                var columns = [];
                if(chunk_size > 0) {
                    while (owners.length) {
                        columns.push(owners.splice(0, chunk_size));
                    }
                }
                else {
                    columns.push(owners);
                }

                var html = '';

                //looping through equal parts and listing names
                columns.forEach((col , index) => {
                    
                    // get html of opening column size
                    html += getColumn(index);

                    //loop through each person in the column and output button
                    col.forEach(person => {
                        var registeredClass = person.registered ? 'registered-owner' : 'not-registered';
                        html +=  '<a class="d-block ' + registeredClass + '" href="/update-owner-details/owner?FIRST_NAMES=' + person.first_names + '&LAST_NAME=' + person.last_name + '&ID=' + person.id_number + '&REGISTERED=' + registeredClass + '">' +
                                            person.first_names + ' ' + person.last_name + 
                                        '</a>';// +
                    });

                    //closing the column
                    html += '</div>';

                });
                
                list.append(html);
                
            },
        });

    });

    //get the html for a column of names. The divs have reordering based on screen suze.
    function getColumn(index) {
        var columnHTML = '';
        if (index + 1 == 3) {
            columnHTML = '<div class="col-12 col-sm-6 order-sm-' + index + ' col-lg-3 order-lg-' + (index + 1) + '">';
        }
        else if (index + 1 == 2) {
            columnHTML = '<div class="col-12 col-sm-6 order-sm-' + (index + 2) + ' col-lg-3 order-lg-' + (index + 1) + '">';
        }
        else if (index + 1 == 5) {
           columnHTML = '<div class="col-12 col-sm-6 order-sm-' + (index + 1) + ' col-lg-3 order-lg-' + (index + 1) + ' ml-auto">';
        }
        else {
            columnHTML = '<div class="col-12 col-sm-6 order-sm-' + (index + 1) + ' col-lg-3 order-lg-' + (index + 1) + '">';
        } 
        return columnHTML;
    }

    $('.owners-by-name__back').click(function() {
        ownersByLastName.hide();
        accordian.fadeToggle();
    });

    $('#ownerAccordian .card-header').click(function() {
        $(this).find('.toggle-btn').toggleClass('rotated');
    });

    

  },
  finalize() {
    // JavaScript to be fired on all pages, after page specific JS is fired
  },
};
