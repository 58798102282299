//global functions

/*eslint-disable */
window.getUrlParameter = function (name) {
  name = name.replace(/[\[]/, '\\[').replace(/[\]]/, '\\]');
  var regex = new RegExp('[\\?&]' + name + '=([^&#]*)');
  var results = regex.exec(location.search);
  return results === null ? '' : decodeURIComponent(results[1].replace(/\+/g, ' '));
};
/*eslint-enable */


export default {
  init() {
    // JavaScript to be fired on all pages

    // mega menu backdrop
    $("#menu-primary-navigation").hover(function () {
      $(".wrap__overlay").toggleClass("visible");  //Toggle the visible class to the area is hovered
    });

    // lightbox
    $(document).on('click', '[data-toggle="lightbox"]', function(event) {
      event.preventDefault();
      $(this).ekkoLightbox();
    });

    // mobile navigation
    $('.hamburger').click(function() {
      $(this).toggleClass('is-active');
      $('.mobile-nav').toggleClass('is-active');
    });

    // $('.wrap').css('padding-top', 112);
    $('.page-header__slider').slick( {
      arrows: false,
      rows: 0,
      fade: true,
      cssEase: 'linear',
      infinite: true,
      speed: 1000,
      autoplay: true,
      autoplaySpeed: 5000,
    });

    //parallax effect on the page header
    $(window).scroll(function() {
      var scrolledY = $(window).scrollTop();
      $('.page-header__bg').css('background-position', 'center ' + ((scrolledY)) + 'px');
    });


  },
  finalize() {
    // JavaScript to be fired on all pages, after page specific JS is fired
  },
};
