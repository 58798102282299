// import external dependencies
import 'jquery';

// Import everything from autoload
import "./autoload/_bootstrap.js"

import "./font-awesome";
import "ekko-lightbox";
import "slick-carousel";
import "jquery-match-height";

// import local dependencies
import Router from './util/Router';
import common from './routes/common';
import home from './routes/home';
import ownerSearch from './routes/owner-search';
import owner from './routes/update-details';
import "./util/fontawesome";

/** Populate Router instance with DOM routes */
const routes = new Router({
  // All pages
  common,
  // Home page
  home,
  
  ownerSearch,

  owner,
});

// Load Events
jQuery(document).ready(() => routes.loadEvents());
